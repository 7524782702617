<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <v-card>
      <v-card-title><span class="text-h5">Login</span></v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form" @submit.prevent="submit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="token"
                  prepend-icon="mdi-key"
                  label="Token"
                  :rules="validations.token"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex flex-row-reverse">
                <v-btn type="submit" color="primary">Login</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      token: "",
      valid: true,
      validations: {
        token: [(v) => !!v || "Token is required"],
      },
      error: "",
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit('newToken', this.token)
      }
    },
  }
};
</script>