import Vue from 'vue'
import VueRouter from 'vue-router'

import TypesManager from '../views/TypesManager.vue'
import ScanHistory from '../views/ScanHistory.vue'
import CodeScanner from '../views/CodeScanner.vue'
import MappingManager from '../views/MappingManager.vue'
import AssetManager from '../views/AssetManager.vue'
import FindAsset from '../views/FindAsset.vue'
import ScanComparator from '../views/ScanComparator.vue'
import FindAssetScanner from '../components/FindAssetScanner.vue'
import FindManyAssetScanner from '../components/FindManyAssetScanner.vue'
import HomePage from '../views/HomePage.vue'
import AboutPage from '../views/AboutPage.vue'
import SearchDisplayer from '../components/SearchDisplayer.vue'
import FindLabel from '../views/FindLabel.vue'
import LocationManager from '../views/LocationManager.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home Page',
    component: HomePage
  },
  {
    path: '/history',
    name: 'Scan History',
    component: ScanHistory
  },
  {
    path: '/scan',
    name: 'Scan',
    component: CodeScanner
  },
  {
    path: '/types',
    name: 'Types',
    component: TypesManager
  },
  {
    path: '/mapping',
    name: 'Mappings',
    component: MappingManager 
  },
  {
    path: '/asset',
    name: 'Assets',
    component: AssetManager
  },
  {
    path: '/assetfind',
    name: 'Asset Finder',
    component: FindAsset
  },
  {
      path: '/scancomparator',
      name: 'Scan Comparator',
      component: ScanComparator
  },
  {
    path: '/assetfindscanner/:label',
    name: 'Asset FinderScanner',
    component: FindAssetScanner
  },
  {
    path: '/findmanyscanner/',
    name: 'Asset Finder Many',
    component: FindManyAssetScanner
  },
  {
    path: '/labelfind',
    name: 'Label Finder',
    component: FindLabel
  },
  {
    path: '/location',
    name: 'Locations',
    component: LocationManager
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchDisplayer
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `RFID - ${to.name}`;
  next();
})

export default router

export const EventBus = new Vue();
