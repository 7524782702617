<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Asset Finder</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn small v-if="overlay" class="mr-n3" color="primary" dark bottom right fixed @click="closeAction()">
        Close
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn small v-if="!overlay" class="mr-n3" color="primary" dark bottom right fixed @click="$router.push('/')">
        Close
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="my-5" >
      <v-card xs12 md6 flat class="pa-3 d-flex flex-column justify-space-between" fill-height fluid height="200">
        <V-card flat mb-auto>
          <v-autocomplete 
          :items="assetsSN" 
          label="Asset serial number" 
          hint="Please, insert here the asset serial number"
          persistent-hint
          auto-select-first
          v-model="serial"
          @click="clearResult"
          @input="getMapping"
          :disabled="overlay"
          >
          </v-autocomplete>
        </v-card>
        <v-card xs12 md6 flat class="d-flex align-end">
          <v-card-subtitle>Label:</v-card-subtitle>
            <v-card-text
              readonly
              label="Label code:"
              :class="color"
            >
              {{ label }}
            </v-card-text>
          </v-card>
        </v-card>
      <FindAssetScanner 
        flat 
        v-if="overlay" 
        :serial="serial" 
        :label="label" 
        :assetModel="assetModel"
        :overlay="overlay"
      >
      </FindAssetScanner>
    </v-container>
      
  </v-card>
</template>

<script>
import { EventBus } from '../router';
import FindAssetScanner from '../components/FindAssetScanner.vue'

export default {
  components: {
    FindAssetScanner
  },
  
  data() {
    return {
      assetsSN: [],
      label: '',
      serial: '',
      color: '',
      overlay: false,
      assetModel: '',
      show:false
    };
  },
  created() {
    EventBus.$on('gotClicked', scan => {
      console.log('got Clicked event called', scan)
    })
  },
  
  mounted() {
    this.getAssets();
    this.$api.lastPage = '/assetfind'
  },
  methods: {
    getAssets() {
      this.$api.asset.getSerials().then(response => {
        this.assetsSN = response.data;
      })
    },
    getMapping() {
      this.$api.mapping.getLabelBySerial(this.serial).then(response => {
        this.label = response.data;
        this.assetLabel = this.label
        this.getModel();
        this.color = "";
        this.$api.show = false;
        this.overlay = true;
      }).catch(err => {
        console.log(err);
        this.label = "Asset not labeled.";
        this.color = "red lighten-4";
        this.overlay = false;
      })
    },
    clearResult() {
      this.overlay = false;
      this.serial = '';
      this.label = '';
    },
    getModel() {
      this.$api.asset.getOne(this.serial).then(response => {
        this.assetModel = response.data.model;
      }).catch(err => {
        console.log(err);
        this.assetModel = "N.A.";
      })
    },
    closeAction() {
      this.$api.isReturning = false;
      this.clearResult();
      }
  }
}
</script>