<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app temporary v-if="loggedIn">
      <v-list nav>
        <v-list-item-group>
          <v-list-item v-for="route in filteredRoutes" :key="route.path" router :to=route.path >
            <v-list-item-content>
              <v-list-item-title>{{ route.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="loggedIn"
      color="primary"
      app
      dark
      :extended="extended"
    >

      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>RFID-SCANNER</v-app-bar-title>
      
      <v-spacer></v-spacer>
      <v-btn 
        icon
        @click="extension=!extension"
        class="mr-1"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>  

      <template v-slot:extension v-if="extension">
        <v-text-field
          v-model="search"
          :class="{ 'closed' : !extended}"
          @click:prepend-inner="commandSearch(search)"
          class="expanding-search "
          hint="The app will search for labels, models and serials."
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          clearable
          dense
          filled
        ></v-text-field>
      </template>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view v-if="loggedIn"></router-view>
        <LoginDialog v-if="!loggedIn" @newToken="handleNewToken"></LoginDialog>
        
      </v-container>
      
        <v-snackbar 
          top 
          :color="color" 
          v-model="snackbar" 
          timeout="2000"
          text
          text-center
          outlined
        >
          <h3><b class="grey--text text--darken-4">{{snackbarMessage}}</b></h3>
          <template v-slot:action="{ attrs }">
          <v-btn
            @click="this.$api.hideSnackbar()"
            text
            v-bind="attrs"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar> 
    </v-main>
  </v-app>
</template>

<script>

import { routes } from './router/index.js'
import LoginDialog from './components/LoginDialog.vue'
import { bus } from './main'

export default {
  name: "App",
  components: {
    LoginDialog,
  },

  data() {
    return {
      drawer: false,
      routes: routes,
      loggedIn: false,
      filteredRoutes: [],
      snackbar: false,
      snackbarMessage: "",
      color: 'primary',
      searchClosed: true,
      search: null,
      extended: false,
      extension: false,
      display: false
    }
  },
  mounted() {
    let token = localStorage.getItem('token')
    if (token) {
      this.loggedIn = true;
      this.removeRoutesFromMenu();
    }
  },
  methods: {
    handleNewToken(token) {
      localStorage.setItem('token', token)
      this.loggedIn = true
    },
    removeRoutesFromMenu() {
      this.filteredRoutes = this.routes.filter(route =>
        route.name !== 'Asset FinderScanner' &&
        route.name !== 'Asset Finder Many' &&
        route.name !== 'Search');
    },
    displayInput() {
      this.extended = true;      
    },
    closeInput() {
      if (this.search) {
        this.extended = true
      } else {
        this.extended = false
      }
    },
    commandSearch(search) {
      this.$api.searchString = search;
      console.log('app search: ', search)
      // this.$router.push(`/search/`);
      bus.$emit('updateSearch', search);
      this.$router.push({
        path: "/search",
        query: {
          q: search,
          t: new Date().getTime(),
        }
      });
    }
  }
};
</script>
<style lang="sass" >
  .v-input.expanding-search
    .v-input__slot
      cursor: pointer !important

</style>
