<template>
  <v-container>
  <v-data-table xs12 md6 :headers="headers" :items="locations" class="elevation-1 breakWord" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat>
        <v-layout row>
          <v-toolbar-title>Locations</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-layout>
        <v-layout row justify-end>
          <v-btn color="primary" dark class="mb-2" @click="dialog=true">
              New Location
            </v-btn>
        </v-layout>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">New Location</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-form ref="form">
                      <v-text-field 
                        v-model="editedItem.name" 
                        label="Location Name" 
                        autofocus
                        :rules="[rules.required, rules.minCounter, rules.maxCounter, rules.unique]"
                        :error-messages="locationError"
                        counter
                        maxlength="15"
                        minlength="3"
                        @keyup.enter="save"
                        @keyup.escape="close">
                      </v-text-field>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="breakWord">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
      </v-toolbar>
    </template>
    <template v-slot:[`item.export`]="{ item }">
      <v-icon color="primary" @click="exportItem(item)"> mdi-download-outline </v-icon>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
      <v-icon color="warning" @click="deleteItem(item)"> mdi-bucket-outline </v-icon>
    </template>
  </v-data-table>
        <v-snackbar 
        top 
        :color="color" 
        v-model="value" 
        :timeout="time"
        text
        text-center
        outlined
      >
        <h3><b class="grey--text text--darken-4">{{snackbarMessage}}</b></h3>
      </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Export QRCode", value: "export", sortable: false },
        { text: "Delete", value: "delete", sortable: false }
      ],
      newLocation: "",
      locations: [],
      defaultItem: {
        name: "",
      },
      editedItem: {
        name: "",
      },
      dialog: false,
      dialogDelete: false,
      loading: true,
      rules: {
        required: value => !!value || "Required.",
        minCounter: value => value.length >= 3 || "Min 3 Characters.",
        maxCounter: value => value.length <= 15 || "Max 15 Characters.",
        unique: value => !this.locations.some(loc => loc.name.toUpperCase() === value.toUpperCase()) || "Location must be unique, case insensitive."
      },
      locationError: '',
      snackbarMessage: '',
      value: false,
      color: '',
      time:'2000',
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        console.log("input locationsManager component: ", this.editedItem.name)
        let res = await this.$api.location.insert(this.editedItem);
        if (res.status!==201) {
          this.locationError = this.$api.locationError;
        } else {
          console.log("res locationManager component", res)
          this.snackbarMessage = "Location created";
          this.color = "success";
          this.time = "2000";
          this.value = true;
          setTimeout(() => {
            this.getLocations();
            this.close();
          },this.time);

        }
      } else {
        console.log('not valid');
        this.dialog = true;
      }
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {        
      this.loading = true
      this.$api.location.delete(this.editedItem._id).then(() => {
        this.snackbarMessage = "This location was correctly removed from database.";
        this.color = "success";
        this.time = "2000";
        this.value = true;
        setTimeout(() => {
            this.getLocations();
            this.closeDelete();
          },this.time);
      })
      .catch(err => {
        console.log("error", err);
        this.snackbarMessage = "There were an error.";
        this.color = "red";
        this.time = "2000";
        this.value = true;
        setTimeout(() => {
          return;
          },this.time);
      });
    },
    getLocations() {
      this.$api.location.get().then(response => {
        this.locations = response.data.items;
        // console.log(this.types);
        this.loading = false;
      })
    },
    exportItem(item) {
      console.log(item);
      if (!item._id) {
        this.snackbarMessage = 'Invalid Export';
        this.value = true;
        this.color = "red lighten-5";
        // let snackbar = { color: "red lighten-5", show: true, message: 'Invalid Export' };
        // console.log('snackbar: hist', snackbar)
        // this.$api.methods.showSnackbar(snackbar);
      } else {
        this.$api.location.export(item._id);
      }
      
    },
  },
};
</script>
<style lang="sass" scoped>
.breakWord 
  word-break: break-word

</style>