<template>
  <v-dialog :value="dialog" max-width="500px" @input="dialogInput">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        New Mapping
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">New Mapping</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field v-model="editedItem.serial" label="Serial No." outlined dense>
              </v-text-field>
            </v-col>
            <v-col>
              <v-btn @click="overlay = true; readingField = 'serial'" color="primary">Scan Serial No.
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="editedItem.label" label="Label." dense outlined>
              </v-text-field>
            </v-col>
            <v-col>
              <v-btn @click="overlay = true; readingField = 'label'" color="primary">Scan Label
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
      </v-card-actions>
      <ScanOverlay v-if="overlay" @codeRead="overlayCodeRead" @hide="overlay = false">

      </ScanOverlay>

      <v-snackbar top :color="color" v-model="snackbar" timeout="5000" text text-center outlined>
        <h3><b class="grey--text text--darken-4">{{ snackbarMessage }}</b></h3>
        <template v-slot:action="{ attrs }">
          <v-btn @click="snackbar=false" text v-bind="attrs">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import ScanOverlay from './ScanOverlay.vue'
export default {
  components: {
    ScanOverlay
  },
  props: ['value', 'defaultItem', 'mappings'],
  data() {
    return {
      editedItem: {},
      overlay: false,
      readingField: '',
      assetsSN: [],
      snackbarMessage: '',
      snackbar: false,
      color: 'primary',
    }
  },
  mounted() {
    this.editedItem = Object.assign({}, this.defaultItem);
    this.getAssets();
  },
  methods: {
    close() {
      this.dialogInput(false)
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    save() {
      this.editedItem.serial = this.editedItem.serial.trim();
      this.editedItem.label = this.editedItem.label.trim();
      if (this.editedItem.serial === '' || this.editedItem.serial.length < 3 || this.editedItem.serial.length > 25) {
        this.throwSnackbar("Serial Number is required and must have more than 3 and less than 26 chars.");

      } else if (this.mappings.some(map => map.serial.toLowerCase() === this.editedItem.serial.toLowerCase())) {
        this.throwSnackbar("This Serial has already been mapped. Remove this mapping before remapping");

      } else if (!this.assetsSN.some(ass => ass.toLowerCase() === this.editedItem.serial.toLowerCase())) {
        this.throwSnackbar("This asset has not been registered. Register this asset before mapping.");

      } else if (this.editedItem.label.length < 4) {
        this.throwSnackbar("Label is required and must have more than 3 and less than 26 chars.");

      } else if (this.mappings.some(map => map.label.toLowerCase() === this.editedItem.label.toLowerCase())) {
        this.throwSnackbar("This Label has already been mapped. Remove this mapping before remapping");

      } else {
        this.$emit('save', this.editedItem)
        this.close();
      }

    },
    throwSnackbar(message) {
      this.snackbarMessage = message
      this.color = 'red';
      this.snackbar = true;
    },
    dialogInput(value) {
      if (value) {
        this.overlay = true
        this.readingField = 'auto'
      }
      this.$emit('input', value)
    },
    overlayCodeRead(code) {

      if (code.length == 13 || code.length == 12 || code.length == 11) {
        if (code.toLowerCase().startsWith('s')) {
          code = code.slice(1)
        }
      }

      if (this.readingField == "auto") {
        if (code.trim().length <= 13) {
          this.editedItem.serial = code
          if (this.editedItem.label) {
            this.save();
          }
        }
        else {
          this.editedItem.label = code
          if (this.editedItem.serial) {
            this.save();
          }
        }
      }
      else {
        this.overlay = false
        this.editedItem[this.readingField] = code
      }
    },
    getAssets() {
      this.$api.asset.getSerials().then(response => {
        this.assetsSN = response.data;
      })
    },
  },
  computed: {
    dialog() {
      return this.value
    }
  }
}
</script>