<template>
  <v-card xs12 md6 flat >
    <v-toolbar flat>
      <v-toolbar-title>Asset Finder</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn small v-if="!overlay" class="mr-n3" color="primary" dark bottom right fixed @click="closeAction()">
        Close
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn small v-if="overlay" class="mr-n3" color="primary" dark bottom right fixed @click="$router.push('/')">
        Close
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card xs12 md6 v-if="!overlay" >
      <v-card-title class="py-0"> Searching Assets... </v-card-title>
      
      <v-list
        style="height: 400px; overflow-y: scroll"
        flat
        
        
      >
      <v-list-item 
        v-for="label in $api.labelSelected"
        :key="label.name"
        >
         <div class="d-flex flex-no-wrap justify-space-between my-0" :class="{ 'green lighten-5' : label.labelFound }">
        
          <v-list-item-content>
          <v-list-item-title class="py-0">label: {{label.name}}</v-list-item-title>
          <v-list-item-subtitle class="py-0">Serial: {{label.serial}}</v-list-item-subtitle>
          <v-list-item-subtitle class="py-0">Model: {{label.model}}</v-list-item-subtitle>
          <v-progress-linear
            v-model="label.ratio"
            height="10"
          >           
          </v-progress-linear>
          </v-list-item-content>
        
        <v-list-item-action>
          <v-btn
            @click="updateHistory(label)"
            color="success"
            timeout="-1"
            plain
            large
            icon
            v-if="label.labelFound"
          ><v-icon >mdi-update</v-icon>
          </v-btn>
        </v-list-item-action>
      </div>
      </v-list-item>
      </v-list>

    </v-card>


    <v-card-text class="mt-4">
      <v-slider
        v-model="value"
        label="How many items?"
        thumb-label="always"
        :thumb-size="20"
        ticks
      ></v-slider>
      <v-slider
        v-model="correction"
        label="Adjust correction?"
        thumb-label="always"
        :thumb-size="20"
        ticks
        min="1"
        max="100"
      ></v-slider>
      
      <v-snackbar 
        top 
        :color="color" 
        v-model="isSuccess" 
        timeout="3000"
      >
        <h3><b>{{message}}</b></h3>
        <span>
          Label {{labelToBeSearched}}
        </span>
      </v-snackbar>
    </v-card-text>
      <v-btn 
        class="mr-1"
        small
        color="success" 
        v-show="labelFound" 
        dark 
        bottom 
        left 
        fixed 
        @click="updateHistoryMany()" 
        timeout="-1"
      >
        Save labels found to history
        <v-icon>mdi-save</v-icon>
      </v-btn>
      <v-btn 
        small 
        v-if="show && !overlay" 
        class="mr-n3" 
        color="primary" 
        dark 
        bottom 
        right 
        fixed 
        @click="closeAction()"
      >
        Close
      <v-icon>mdi-close</v-icon>
      </v-btn>
  </v-card>
</template>

<script>
export default {
  props:["label", "overlay"],
  data() {
    return {
      labelToBeSearched:this.$route.params.label,
      scannedCodes: [],
      buffer: "",
      history: [],
      loading: true,
      labelFound: false,
      isSuccess: false,
      successCounter: 0,
      notSuccessCounter: 0,
      ratio: 0,
      distinctCodes:0,
      value:20,
      val: 0,
      correction: 90,
      closeDialog: false,
      message: '',
      color: 'success',
      show:false,
    };
  },

  mounted() {
    this.show = this.$api.show;
    this.labelToBeSearched = (this.labelToBeSearched) ? this.labelToBeSearched : this.label; 
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  methods: {
    readCode(code) {
      let counts = {};
      this.scannedCodes.push(code);
      this.scannedCodes = this.scannedCodes.slice((this.value) * (-1));
      this.distinctCodes = this.removeDuplicates(this.scannedCodes);
      for (let l of this.scannedCodes) {
        counts[l] = counts[l] ? counts[l] + 1 : 1;
      }

      for (let tag of this.$api.labelSelected) {
        if (tag.name === code ) {
          tag.labelFound = true;
          this.labelFound = true
          this.message = "Asset found!"
          this.isSuccess = true;
        }
        this.successCounter = this.counter(counts, tag.name);
        this.notSuccessCounter = this.scannedCodes.length - this.successCounter;
        // else {
        //   this.labelFound = false;
        // }
        
        // console.log("scannedCodes", this.scannedCodes);
        // console.log("distinctCodes", this.distinctCodes);
        // console.log("successCounter", this.successCounter);
        // console.log("notSuccessCounter", this.notSuccessCounter);
        // console.log("scanObj to be saved", this.$api.scan);
        if (this.successCounter != 0 && this.notSuccessCounter != 0) {
            tag.ratio = this.successCounter * (this.distinctCodes - 1) * this.correction / this.notSuccessCounter;
            tag.ratio = (tag.ratio > 100) ? 100 : tag.ratio;
        } else {
          tag.ratio = 0;
        }
      }  
    },
    removeDuplicates(arr) {
      return new Set(arr).size;
    },
    handleKeyPress(e) {
      if (e.key == "Enter") {
        this.readCode(this.buffer);
        this.buffer = "";
      } else {
        this.buffer += e.key.trim();
      }
    },
    counter(counts, label) {

        if (!counts[label]) {
          // console.log("counts", counts[label]);
          return 0;
        }
        // console.log("counts", counts[label]);
        // console.log("counter arr", scannedCodes);
        // console.log("label", label);
        return counts[label];
    },

      async updateHistory(label) {
    // async updateHistory(label) {
        let scan = {};
        scan._id = label.id;
        scan.Codes = [label.name];
      let res = await this.$api.history.put(scan);
      console.log("res", res);
      if (res === "Label added to Scan.") {
        // this.removeLabel();
        this.isSuccess = true;
        this.color = "success";
        this.message = res;
        // console.log("success: ", res);
        this.show = false;
        this.$api.isReturning = true;
        this.$api.labelSelected.splice(this.$api.labelSelected.findIndex(tag => tag.name === label.name), 1);
        // this.$router.push('/scancomparator');
      } else {
        this.$api.isSuccess = true;
        this.$api.color = "error"
        this.$api.message = res;
        console.log("error: ", res);
        this.$api.isReturning = true;
        this.$router.push('/scancomparator');
      }
    },

    async updateHistoryMany() {
      console.log("$api.labelSelected", this.$api.labelSelected);
      let scan1 = { _id: this.$api.labelSelected[0].id, Codes: [] };
      let scan2 = { _id: '', Codes: [] };
      this.$api.labelSelected.forEach(l => {

        if (Object.prototype.hasOwnProperty.call(l, "labelFound")) {
          if (l.id === scan1._id) {
            scan1.Codes.push(l.name);
          } else {
            scan2._id = l.id;
            scan2.Codes.push(l.name);
          }
        }
      });
      console.log("scan1", scan1);
      console.log("scan2", scan2);
      let scanArr = [scan1, scan2];

      for (let scan of scanArr) {
        if (scan.Codes.length == 0)
          continue;
        let res = await this.$api.history.put(scan);
        console.log("res", res);
        if (res === "Label added to Scan." || res === "Labels added to Scan.") {
          // this.removeLabel();
          this.$api.isSuccess = true;
          this.$api.color = "success";
          this.$api.message = res;
          console.log("success: ", res);
          this.show = false;
        } else {
          this.$api.isSuccess = true;
          this.$api.color = "error"
          this.$api.message = res;
          console.log("error: ", res);
        }
      }
      this.$api.isReturning = true;
      this.$router.push('/scancomparator');

    },
    closeAction() {
      this.$api.isReturning = true;
      this.$router.push(this.$api.lastPage);
    }
  },
};
</script>

<style scoped>
.elevation-n1 {
  box-shadow:
    inset 0 4px 2px -2px rgba(0, 0, 0, 0.2),
    inset 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    inset 0 2px 6px 0 rgba(0, 0, 0, 0.12) !important;
}
.my-list {
    
    height: 100vh;
    overflow-y: auto;
  }
</style>
