<template>
  <v-card xs12 md6>
    <v-toolbar flat mb-5>
      <v-layout row >      
      <v-toolbar-title >Scan Comparator</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>  
        </v-layout>
      
      <v-layout class="ma-1" row justify-end>
      <v-btn 
        class="ma-1"
        small 
        color="success" 
        @click="splitSelectedScan()" 
        v-if="!display">              
        <span>Compare</span>
        <v-icon dark>mdi-compare</v-icon>
      </v-btn>
      <v-btn 
        class="ma-1"
        small 
        color="primary" 
        @click="exportComparison(scanComparisonArr)" 
        v-if="display">              
        <span>Export All</span>
        <v-icon dark>mdi-download-outline</v-icon>
      </v-btn>
      <v-btn 
        class="ma-1"
        small 
        color="primary" 
        @click="closer()"
        >              
        <span>Close</span>
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
          </v-layout>
    </v-toolbar>
    <v-card xs12 md6 class="mt-6">
      <v-spacer></v-spacer>
      <v-card-text 
        class="text-h6 font-weight-black text-center" 
        v-if="!display"
        >
        Choose only two Scans to compare.
      </v-card-text>
      </v-card>
    <v-snackbar 
      top 
      color="warning" 
      v-model="showAlertMsg"
    >
      <h2>{{alertMsg}}</h2>
    </v-snackbar>     
    <ScanComparatorDisplayer 
      v-if="display" 
      
      :scanATime="scanATime" 
      :scanBTime="scanBTime" 
      :scanComparisonArr="scanComparisonArr" 
      :scanAType="scanAType" 
      :scanBType="scanBType"
      :scanALocation="scanALocation"
      :scanBLocation="scanBLocation" />
    <v-data-table
      v-if="!display"
      v-model="selected"
      :headers="headers"
      :items="summaries"
      :colspan="headers.length"
      
      @input="validateMaxNumberOfChoices"
      item-key="time"
      show-select
    >
    </v-data-table>
  </v-card>
</template>

<script>
import ScanComparatorDisplayer from '../components/ScanComparatorDisplayer.vue'
export default {
  components: {
    ScanComparatorDisplayer
  },
  data() {
    return {
      headers: [
        { text: "Time", value: "time", sortable: true },
        { text: "Type", value: "type", sortable: true },
        { text: "Location", value: "location", sortable: true },
        { text: "Scanned Codes", value: "quantity", sortable: true, align: "center" }
      ],

      selected: [],
      summaries: [],
      scanATime: '',
      scanBTime: '',
      scanAType: '',
      scanBType: '',
      scanALocation: '',
      scanBLocation:'',
      display: this.$api.display,
      scanComparisonArr: [],
      scanComparisonObj: {
          name: '',
          children: [{}]
        },
      alertMsg: 'You can only select two scans!',
      showAlertMsg: false,
      exportHeaders: ["name", "serial", "model"],
    }
  },
  mounted() {
    this.$api.history.getSummaries()
      .then(response => {
        this.summaries = response.data;
        this.loading = false;
        this.display = false;
        if (this.$api.isReturning === true) {
          this.selected = this.$api.selected;
          this.splitSelectedScan();
        } 
      })
      .catch((error) => {
        console.log('getSummaries error', error);
        return;
      });

  },
  methods: {
    validateMaxNumberOfChoices(selected) {
      if (selected.length > 2) {    
        this.showAlertMsg = true;
        setTimeout(() => { selected.pop(); }, 2000);
      } else {
        this.showAlertMsg = false;
      }
    },
    splitSelectedScan() {
 
      this.$api.selected = this.selected;

      if (this.selected[0].time < this.selected[1].time) {
        this.scanATime = this.selected[0].time;
        this.scanAType = this.selected[0].type;
        this.scanALocation = this.selected[0].location;
        this.scanBTime = this.selected[1].time;
        this.scanBType = this.selected[1].type;
        this.scanBLocation = this.selected[1].location;
      } else {
        this.scanATime = this.selected[1].time;
        this.scanAType = this.selected[1].type;
        this.scanALocation = this.selected[1].location;
        this.scanBTime = this.selected[0].time;
        this.scanBType = this.selected[0].type;
        this.scanBLocation = this.selected[0].location;
      }

      this.$api.history.getViews(this.selected[0].id, this.selected[1].id)
        .then(res => { 
          this.scanComparisonArr = res.data;
          this.scanComparisonArr[0].time = this.scanATime;
          this.scanComparisonArr[1].time = this.scanBTime;
          this.scanComparisonArr[0].type = this.scanAType;
          this.scanComparisonArr[1].type = this.scanBType;
          this.scanComparisonArr[0].location = this.scanALocation;
          this.scanComparisonArr[1].location = this.scanBLocation;
          this.display = true
        })
        .catch((error) => {
          console.log('split error', error);
          return;
        });
      
    },

    exportComparison(scanComparisonArr) {
      let download = [];
      
      download.push(",Labels scanned at, not scanned at");
      download.push(`Time, ${scanComparisonArr[0].time}, ${scanComparisonArr[1].time}`);
      download.push(`Type, ${scanComparisonArr[0].type}, ${scanComparisonArr[1].type}`);
      download.push(`Location, ${scanComparisonArr[0].location ?? ""} , ${scanComparisonArr[1].location ?? ""}`);
      download.push("Label, Serial Number, Model"); 
      
      if (scanComparisonArr[0].children[0].name === "No labels found.") {

        download.push("There are no data to be displayed.");
      } else {
        download = this.childrenExportBuilder(scanComparisonArr[0].children, download);
      }

      download.push(",Labels scanned at, not scanned at");
      download.push(`Time, ${scanComparisonArr[1].time}, ${scanComparisonArr[0].time}`);
      download.push(`Type, ${scanComparisonArr[1].type}, ${scanComparisonArr[0].type}`);
      download.push(`Location, ${scanComparisonArr[1].location ?? ""} , ${scanComparisonArr[0].location ?? ""}`);
      download.push("Label, Serial Number, Model"); 
      
      if (scanComparisonArr[1].children[0].name === "No labels found.") {
        download.push("There are no data to be displayed.");
      } else {
        download = this.childrenExportBuilder(scanComparisonArr[1].children, download);
      }

      const csv = download.join('\n');
      this.$api.methods.download(csv);
      
    },

    childrenExportBuilder(scan, download) {
      for (let row of scan) {
        const values = this.exportHeaders.map(header => {
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });

        download.push(values.join(','));
      }
      return download;
    },

    closer() {
      this.$api.isReturning = false;
      this.$api.selected = {};
      if (this.display === false) {
        this.$router.push('/');
      } else {
        this.selected = [];
        this.display = false;
        this.scanComparisonObj = {};
        this.scanComparisonArr = [];
        this.$forceUpdate();
      }
    }
  }
}
</script>