<template>
  <v-overlay absolute :value="true" @click="$emit('hide')">
    <span class="text-h5">Scanning...</span>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      buffer: '',
    }
  },
  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(e) {
      if (e.key == "Enter") {
        this.$emit("codeRead", this.buffer);
        this.buffer = "";
      } else {
        this.buffer += e.key.trim();
      }
    },
  }
}
</script>