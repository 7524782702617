<template>
  <v-card >
    <v-snackbar 
      top 
      :color="$api.color" 
      v-model="$api.isSuccess" 
      :timeout="2000"
      text
      text-center
      outlined
    >
      <h3><b class="grey--text text--darken-4">{{$api.message}}</b></h3>
    </v-snackbar>
    
    <v-snackbar 
      top 
      :color="color" 
      v-model="value" 
      :timeout="time"
      text
      text-center
      outlined
    >
      <h3><b class="grey--text text--darken-4">{{snackbarMessage}}</b></h3>
    </v-snackbar>
  
    <v-container>

    <v-row word-break:true >
      <v-col>
        <strong>Scan A: </strong><br/>
          {{ scanATime | moment("MM/DD/YYYY hh:mm a") }}<br/>
          Type: {{ scanAType }}<br/>
          Location: {{scanALocation}}
      </v-col>
      <v-col>
        <strong>Scan B: </strong><br/>
        {{scanBTime | moment("MM/DD/YYYY hh:mm a") }}<br/>
        Type: {{ scanBType }}<br/>
        Location: {{scanBLocation}}
      </v-col>
      
    </v-row>

      <v-col class="mt-n6">
    <v-card-actions v-if="!display">
      <v-flex class="text-xs-right"></v-flex>
      <v-btn 
        class="mx-n4"
        small 
        color="primary" 
        @click="findSelectedLabels(selected)" 
      >              
        <span class="mr-4">Find Many</span>
        <v-icon class="mr-1" dark>mdi-tag-search-outline</v-icon>
      </v-btn>
    </v-card-actions>
        <v-treeview
            selection-type="leaf"
            selectable
            item-disabled="locked"
            dense
            class="ml-n8 mr-n4 text-subtitle-2 breakWord v-treeview "
            open-all
            :items="scanComparisonArr"
            v-model="selected"
            return-object
            item-key="id"
          >
            <template v-slot:append="{ item }">
              <v-btn 
                large
                icon
                color="primary" 
                :disabled="item.locked"
                class="mr-n6"
                v-if="item.name.split(' ')[0] =='Labels'"
                @click="exportComparison(item)"
              >
              <v-icon >
                mdi-download-outline
              </v-icon>
              </v-btn>
              <v-btn 
                large
                icon
                color="primary" 
                :disabled="item.name==='No labels found.'"
                class="mr-n6"
                v-else
                @click="findLabel(item)"
              >
              <v-icon>
                mdi-tag-search-outline
              </v-icon>
              </v-btn>
            </template>
            <template v-slot:label="{ item }">
              <span v-if="item.name.split(' ')[0] === 'Labels'" class="text-wrap">{{ item.name }}</span>
              <span v-else>{{ item.name }} <br />{{ item.serial }}<br />{{ item.model }}</span>
              <v-divider />
            </template>
          </v-treeview>
        </v-col>
    </v-container>
  </v-card>
</template>

<script>

export default {

  props: [
    "scanComparisonArr",
    "scanAType",
    "scanBType",
    "scanATime",
    "scanBTime",
    "display",
    "scanALocation",
    "scanBLocation"
  ],

  data() {
    return {
      singleSelect: false,
      selected: [],
      openAll: true,
      exportHeaders: ["name", "serial", "model"],
      snackbarMessage: '',
      value: false,
      color: '',
      time:"2000",
    }
  },
  mounted() {
    this.$api.lastPage = '/scancomparator';
    window.addEventListener("keypress", this.handleKeyPress);

  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  methods: {
    checkSerial(scan) {
      if (scan.serial === 'label not mapped')
        return true;
      return false;
    },
    findSelectedLabels(selected) {

      if (selected.length != 0) {
        selected.forEach((label) => {
          if (this.scanComparisonArr[0].children.some(c => c.name === label.name)) {
            label.id = this.scanComparisonArr[1].id;
          } else if (this.scanComparisonArr[1].children.some(c => c.name === label.name)){
            label.id = this.scanComparisonArr[0].id;
          } else {
            console.log("label.name: ", label.name);
            console.log("failed ");
          }
          return selected;
        })
        this.$api.labelSelected = selected;
        this.$router.push('/findmanyscanner/');
      } else {
        console.log("empty ");
        this.snackbarMessage = 'Please, choose at least one label';
        this.value = true;
        this.color = "warning";
        this.time = 2000;
      }
    },
    findLabel(item) {

      let scan = {};
      this.$api.show = 'true';
      this.$api.item = item;

      if (this.scanComparisonArr[0].children.some(child => child.name === item.name)) {
        scan._id = this.scanComparisonArr[1].id;
        scan.time = this.scanComparisonArr[1].time;
        scan.type = this.scanComparisonArr[1].type;
      } else if (this.scanComparisonArr[1].children.some(child => child.name === item.name)) {
        scan._id = this.scanComparisonArr[0].id;
        scan.time = this.scanComparisonArr[0].time;
        scan.type = this.scanComparisonArr[0].type;
      } else {
        console.log("Error - Label not found.")
      }
        scan.codes = [item.name];
        this.$api.scan = scan;
      this.$router.push(`/assetfindscanner/${item.name}`);
    }
  }
}
</script>
<style scoped>

.breakWord {
  word-break: break-word;
  white-space: normal !important;
}
.v-treeview-node__content, .v-treeview-node__label, .v-treeview {
  flex-shrink: 1;
  white-space: normal !important;
}

.v-treeview {
  white-space: pre-wrap; 
  word-wrap: break-word;
}

</style>
