<template>
  
  <v-container fluid>
  <v-layout justify-center align-center>
    <v-flex shrink>

        <v-img
        contain
        max-height="200"
        min-height="200"
        src="RFID.png"
        class="home0"
      ></v-img>
      
    </v-flex>
  </v-layout>
</v-container>
  
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>

.home0
  position: absolute
  left: 54%
  top: 30%
  transform: translate(-50%, -50%)
.home1
  position: absolute
  left: 50%
  top: 51%
  transform: translate(-50%, -50%)
  opacity: 0.5
.home2
  position: absolute
  left: 50%
  top: 59%
  transform: translate(-50%, -50%)
  opacity: 0.5
</style>