<template>
  <v-container >
  <v-data-table :key="trigger" xs12 md6 :headers="headers" :items="searches" class="elevation-1 breakWord" :loading="loading">
    
  </v-data-table>
        
  </v-container>
</template>

<script>
import { bus } from '../main';
export default {
  props: [
    "search",
  ],

  data() {
    return {
      headers: [
        { text: "Serial", value: "serial", sortable: true},
        { text: "Model", value: "model", sortable: true },
        { text: "Label", value: "label", sortable: true }
      ],
      
      searches: [],
      loading: true,
      trigger:0
      
    };
  },
  created() {
    bus.$on('updateSearch', (search) => {
      this.getSearches(search);
      console.log("bus", search)

    })
  },
  mounted() {
    console.log("display", this.$api.searchString)
    this.getSearches(this.$api.searchString);
  },
  beforeDestroy() {
    this.$api.searchString = '';
  },  
  methods: {
    getSearches(search) {
      this.searches = [];
      this.$api.search.get(search).then(response => {
        this.searches = response.data;
        console.log("data", response.data);
        this.loading = false;
      })
    },
  },
};
</script>
<style lang="sass" scoped>
.breakWord 
  word-break: break-word

</style>