<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Label Finder</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container class="my-5">
      <v-card xs12 md6 flat class="pa-3 d-flex flex-column justify-space-between" fill-height fluid height="200">
        <v-card flat mb-auto>
          <v-text-field label="Label" hint="Please, insert here the asset label" persistent-hint v-model="label"
            @click="clearResult" @input="buffer = ''; progress = 0">
          </v-text-field>
          <v-progress-linear :value="progress"></v-progress-linear>
        </v-card>
      </v-card>

    </v-container>

  </v-card>
</template>

<script>

export default {
  data() {
    return {
      label: '',
      buffer: '',
      progress: 0,
      interval: null
    };
  },
  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
    this.interval = setInterval(this.decreaseProgress, 100);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
    clearInterval(this.interval)
  },
  methods: {
    handleKeyPress(e) {
      if (e.key == "Enter") {
        if (this.buffer == this.label) {
          this.progress = 110
        }
        this.buffer = "";
      } else {
        this.buffer += e.key.trim();
      }
    },
    clearResult() {
      this.label = '';
    },
    decreaseProgress() {
      this.progress -= 1;
    }
  }
}
</script>