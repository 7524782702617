<template>
  <div>
    <br>
    <v-row justify="center">
    <v-img src="rfidlogo.png" max-width="150"></v-img>
    </v-row>
    <v-row justify="center">
      <h1>RFID Scanner App</h1>
    </v-row>
    <br>
    <br>
    <h2>Navigation Bar</h2>
    <p>From the home page of the App the user can navigate to all pages using the left Menu Bar accessible from the top left three bar button.</p>
    <v-row justify="center">
    <v-img src="menubar.png" style="border: 1px solid" max-width="600"></v-img>
    </v-row>
    <v-row justify="center">Figure 1- MenuBar.</v-row>
    <br>
    <h2>Scan History</h2>
    <p>In this page, all scans saved in the App are shown, paginated in the default number of 10 scans.</p>
    <v-row justify="center">
    <v-img src="historypage.png" style="border: 1px solid" max-width="600"></v-img>
    </v-row>
    <v-row justify="center">Figure 2- History page.</v-row>
    <br>
    <p>All information of each scan is displayed.</p>
    <ul>
      <li>Time;</li>
      <li>Type; and</li>
      <li>Number of scanned Codes (the labels scanned can be seen by expanding the item).</li>
    </ul>
    <p>The default sort is by time, ascending. The columns can be sorted ascending and descending by all columns.</p>
    <p>For each item, two icon buttons are displayed: Export and Delete</p>
    <span> The export button </span><v-icon color="primary inline">mdi-download-outline</v-icon><span>commands the creation of a csv file containing the data of the scan.</span>
    <br><br>
    <v-row justify="center">
    <v-img src="scanexport.png" style="border: 1px solid" max-width="600"></v-img>
    
    </v-row>
    <v-row justify="center">Figure 3- Export csv opened in the Excel.</v-row>
   
    <br>
    <span>The delete button </span><v-icon color="warning inline">mdi-bucket-outline</v-icon><span>commands the removal of the respective scan from the database.</span>
    <p>In the top right corner of the screen, there are two buttons: Clear All and New.</p>
    <span>The New button </span><v-img src="newbutton.png" class="inline"> </v-img><span> opens the Scan page that will be exposed further in this tutorial.</span> <br>
    <span>The Clear All button </span><v-img src="ClearAllButton.png" class="inline"> </v-img><span> commands the removal of all scans data from the database.</span>
    <p>At the bottom of the page, the number of scans per page can be set. The user can navigate the pages using the &lt; and &gt; arrows.</p>
    <br><br>
    <h2>Scan</h2>
    <p>The Scan page allows the user to insert the type and performs the labels scan.</p>
    <p>A text field straight below the Title of the page allow the user to select the Type of the scan he or she intends to perform. If the user is not satisfied by the listed type options, he or she can insert new types at the Types page that will be exposed further in this tutorial.</p>
    <p>With this page open, trigging the scanner, it will send the information to the app and the codes of the scanned labels will be shown in the screen. As shown in the Figure 4.</p>
    <span>This page can be accessed by the lateral menu bar or by clicking in the new button </span><v-img src="newbutton.png" class="inline"> </v-img><span> in the History scan page.</span>
     <br><br><br>
    <v-row justify="center">
    <v-img src="ScanPage.png" style="border: 1px solid" max-width="400"></v-img>
    </v-row>
    <v-row justify="center">Figure 4- Scan page.</v-row>
    <br>
    <span>As you can see, this page has two buttons at its bottom: </span>
    <br /><br />
    <span><v-btn fab dark small color="red"><v-icon class="inline">mdi-close</v-icon></v-btn> the CANCEL or CLOSE button, that cancels the scan and returns to Scan History page; and </span> 
    <br /><br />
    
    <span><v-btn fab dark small color="success"><v-icon class="inline">mdi-check</v-icon></v-btn> the SAVE button that picks the date and time information, the chosen type and the scanned codes and saves these as a new history item.</span>
    <p></p>
    <h2>Types
    </h2>
    <h2>Mappings
    </h2>
    <h2>Assets
    </h2>
    <h2>Asset Finder
    </h2>
    <h2>Scan Comparator
    </h2>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      markdown: " This is the About Page, under construction...  ",
    };
  },
};
</script>

<style scoped>

.home0 {
  position: absolute;
  left: 54%;
  top: 30%;
  transform: translate(-50%, -50%);
}
.inline{
  display:inline-block;   
}
</style>