<template>
  <v-card xs12 md6 flat >
    <v-card xs12 md6 v-if="!overlay">
      <v-card-title class="py-0"> Searching Asset </v-card-title>
      <div class="d-flex flex-no-wrap justify-space-between" :class="{ 'green lighten-5' : labelFound }">
        <div >
          <v-card-text class="py-0">label: {{labelToBeSearched}}</v-card-text>
          <v-card-text class="py-0">Serial: {{this.$api.item.serial}}</v-card-text>
          <v-card-text class="py-0">Model: {{this.$api.item.model}}</v-card-text>
        </div>
        <v-card-actions>
          <v-btn
            @click="updateHistory()"
            color="success"
            timeout="-1"
            plain
            elevation="0"
            
            v-if="labelFound && show"
            outlined
          >Update<v-icon >mdi-update</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
        
      

    </v-card>
    <v-card-text class="mt-4">
      <v-slider
        v-model="value"
        label="How many items?"
        thumb-label="always"
        :thumb-size="20"
        ticks
      ></v-slider>
      <v-slider
        v-model="correction"
        label="Adjust correction?"
        thumb-label="always"
        :thumb-size="20"
        ticks
        min="1"
        max="100"
      ></v-slider>
      <v-row>
        <v-col>
          <v-progress-linear
            v-model="ratio"
            height="35"
          >
            <strong>{{ Math.ceil(ratio) }}%</strong>
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-snackbar 
        top 
        :color="color" 
        v-model="isSuccess" 
        timeout="3000"
      >
        <h3><b>{{message}}</b></h3>
        <span>
          Label {{labelToBeSearched}}
        </span>
      </v-snackbar>
    </v-card-text>

      <v-btn 
        small 
        v-if="show && !overlay" 
        class="mr-n3" 
        color="primary" 
        dark 
        bottom 
        right 
        fixed 
        @click="closeAction()"
      >
        Close
      <v-icon>mdi-close</v-icon>
      </v-btn>
  </v-card>
</template>

<script>
export default {
  props:["label", "overlay"],
  data() {
    return {
      labelToBeSearched:this.$route.params.label,
      scannedCodes: [],
      buffer: "",
      history: [],
      loading: true,
      labelFound: false,
      isSuccess: false,
      successCounter: 0,
      notSuccessCounter: 0,
      ratio: 0,
      distinctCodes:0,
      value:20,
      val: 0,
      correction: 90,
      closeDialog: false,
      message: '',
      color: 'success',
      show:false,
    };
  },

  mounted() {
    this.show = this.$api.show;
    this.labelToBeSearched = (this.labelToBeSearched) ? this.labelToBeSearched.toUpperCase() : this.label.toUpperCase(); 
    window.addEventListener("keypress", this.handleKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  methods: {
    readCode(code) {
      code = code.toUpperCase();
      if (code == this.labelToBeSearched) {
        this.labelFound = true;
        this.message = "Asset found!"
        this.isSuccess = true;
      }
      // else {
      //   this.labelFound = false;
      // }
      this.scannedCodes.push(code);
      this.scannedCodes = this.scannedCodes.slice((this.value) * (-1));
      this.distinctCodes = this.removeDuplicates(this.scannedCodes);
      this.successCounter = this.counter(this.scannedCodes, this.labelToBeSearched);
      this.notSuccessCounter = this.scannedCodes.length - this.successCounter;
      // console.log("scannedCodes", this.scannedCodes);
      // console.log("distinctCodes", this.distinctCodes);
      // console.log("successCounter", this.successCounter);
      // console.log("notSuccessCounter", this.notSuccessCounter);
      // console.log("scanObj to be saved", this.$api.scan);
      if (this.successCounter != 0 && this.notSuccessCounter != 0) {
          this.ratio = this.successCounter * (this.distinctCodes - 1) * this.correction / this.notSuccessCounter;
          this.ratio = (this.ratio > 100) ? 100 : this.ratio;
      } else {
        this.ratio = 0;
      }
    },
    removeDuplicates(arr) {
      return new Set(arr).size;
    },
    handleKeyPress(e) {
      if (e.key == "Enter") {
        this.readCode(this.buffer);
        this.buffer = "";
      } else {
        this.buffer += e.key.trim();
      }
    },
    counter(scannedCodes, label) {
      const counts = {};
      for (const l of scannedCodes) {
        counts[l] = counts[l] ? counts[l] + 1 : 1;
      }
        if (!counts[label]) {
          // console.log("counts", counts[label]);
          return 0;
        }
        // console.log("counts", counts[label]);
        // console.log("counter arr", scannedCodes);
        // console.log("label", label);
        return counts[label];
    },

    async updateHistory() {
      console.log("this API scan", this.$api.scan)
      let res = await this.$api.history.put(this.$api.scan);
      console.log("res", res);
      if (res === "Label added to Scan.") {
        // this.removeLabel();
        this.$api.isSuccess = true;
        this.$api.color = "success";
        this.$api.message = res;
        // console.log("success: ", res);
        this.show = false;
        this.$api.isReturning = true;
        this.$router.push('/scancomparator');
      } else {
        this.$api.isSuccess = true;
        this.$api.color = "error"
        this.$api.message = res;
        console.log("error: ", res);
        this.$api.isReturning = true;
        this.$router.push('/scancomparator');
      }
    },

    removeLabel() {      
      for (let i = 0; i < 2; i++) {
        let index = this.$api.selected[i].codes.indexOf(this.labelToBeSearched);
        if (index > -1) {
          this.$api.selected[i].codes.splice(index, 1);
        }
      }
    },

    closeAction() {
      this.$api.isReturning = true;
      this.$router.push(this.$api.lastPage);
    }
  },
};
</script>
