<template>
  <v-container>
  <v-data-table xs12 md6 :headers="headers" :items="types" class="elevation-1 breakWord" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat>
        <v-layout row>
          <v-toolbar-title>Types</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-layout>
        <v-layout row justify-end>
          <v-btn color="primary" dark class="mb-2" @click="dialog=true">
              New Type
            </v-btn>
        </v-layout>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">New Type</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-form ref="form">
                      <v-text-field 
                        v-model="editedItem.name" 
                        label="Type Name" 
                        autofocus
                        :rules="[rules.required, rules.minCounter, rules.maxCounter, rules.unique]"
                        :error-messages="typeError"
                        counter
                        maxlength="15"
                        minlength="3"
                        @keyup.enter="save"
                        @keyup.escape="close">
                      </v-text-field>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="breakWord">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon color="warning" @click="deleteItem(item)"> mdi-bucket-outline </v-icon>
    </template>
  </v-data-table>
        <v-snackbar 
        top 
        :color="color" 
        v-model="value" 
        :timeout="time"
        text
        text-center
        outlined
      >
        <h3><b class="grey--text text--darken-4">{{snackbarMessage}}</b></h3>
      </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false},
        { text: "Delete", value: "actions", sortable: false },
      ],
      newType: "",
      types: [],
      defaultItem: {
        name: "",
      },
      editedItem: {
        name: "",
      },
      dialog: false,
      dialogDelete: false,
      loading: true,
      rules: {
        required: value => !!value || "Required.",
        minCounter: value => value.length >= 3 || "Min 3 Characters.",
        maxCounter: value => value.length <= 15 || "Max 15 Characters.",
        unique: value => !this.types.some(typ => typ.name.toLowerCase() === value.toLowerCase()) || "Type must be unique, case insensitive."
      },
      typeError: '',
      snackbarMessage: '',
      value: false,
      color: '',
      time:'2000',
    };
  },
  mounted() {
    this.getTypes();
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        console.log("input typesManager component: ", this.editedItem.name)
        let res = await this.$api.type.insert(this.editedItem);
        if (res.status!==200) {
          this.typeError = this.$api.typeError;
        } else {
          console.log("res typesManager component", res)
          this.snackbarMessage = res.data;
          this.color = "success";
          this.time = "2000";
          this.value = true;
          setTimeout(() => {
            this.getTypes();
            this.close();
          },this.time);

        }
      } else {
        console.log('not valid');
        this.dialog = true;
      }
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true
      this.$api.type.delete(this.editedItem._id).then(() => {
        this.snackbarMessage = "This type was correctly removed from database.";
        this.color = "success";
        this.time = "2000";
        this.value = true;
        setTimeout(() => {
            this.getTypes();
            this.closeDelete();
          },this.time);
      })
      .catch(err => {
        console.log("error", err);
        this.snackbarMessage = "There were an error.";
        this.color = "red";
        this.time = "2000";
        this.value = true;
        setTimeout(() => {
          return;
          },this.time);
      });
    },
    getTypes() {
      this.$api.type.get().then(response => {
        this.types = response.data;
        // console.log(this.types);
        this.loading = false;
      })
    }
  },
};
</script>
<style lang="sass" scoped>
.breakWord 
  word-break: break-word

</style>