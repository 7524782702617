<template>
  <v-data-table :headers="headers" :items="mappings" class="elevation-1" :loading="loading">
    <template v-slot:top>
                <v-snackbar 
        top 
        :color="color" 
        v-model="value" 
        timeout="2000"
        text
        text-center
        outlined
      >
        <h3><b class="grey--text text--darken-4">{{snackbarMessage}}</b></h3>
      </v-snackbar>
      <v-toolbar flat>
        <v-toolbar-title>Mapping</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <MappingDialog 
          v-model="dialog"
          :mappings="mappings" 
          :defaultItem="defaultItem" 
          @save="saveMapping"
          >
        </MappingDialog>
        
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card >
            <v-card-title class="breakWord">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon color="warning" @click="deleteItem(item)"> mdi-bucket-outline </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import MappingDialog from '../components/MappingDialog.vue'

export default {
  components: {
    MappingDialog
  },
  data() {
    return {
      headers: [
        {
          text: "Serial No",
          value: "serial",
          sortable: true,
        },
        {
          text: "Model",
          value: "model",
          sortable: true,
        },
        {
          text: "Label",
          value: "label",
          sortable: true,
        },
        {
          text: "Delete",
          value: "actions",
          sortable: false
        },
      ],
      newMap: "",
      mappings: [],
      defaultItem: {
        serial: "",
        model: "N/A",
        label: "",
      },
      editedItem: {
        serial: "",
        label: ""
      },
      dialog: false,
      dialogDelete: false,
      overlay: false,
      loading: true,
      snackbarMessage: '',
      value: false,
      color: '',
    };
  },
  mounted() {
    this.getMappings()
  },
  methods: {
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.loading = true
      this.$api.mapping.delete(this.editedItem._id).then(() => {
        this.snackbarMessage = "This mapping was correctly removed from database.";
        this.value = true;
        this.color = "success";        
        this.closeDelete();
        this.getMappings();
      })
      .catch(err => {
        console.log("error", err);
        this.snackbarMessage = "There were an error.";
        this.value = true;
        this.color = "red";
      });
    },
    saveMapping(item) {
      this.loading = true;
      this.$api.mapping.insert(item).then(() => {
        this.getMappings();
        this.snackbarMessage = 'Asset mapped successfully.';
        this.color = "success";
        this.value = true;
      })
    },
    getMappings() {
      this.$api.mapping.get().then(response => {
        console.log("mappings:", response);
        this.mappings = response.data;
        this.loading = false;
        
      })
    }
  },
};
</script>
<style scoped>
.breakWord {
  word-break: break-word;
}
</style>