<template>
  <v-card>
    <v-card-title> Scan </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-row>
            <v-select ref="typeselect" v-model="type" :items="typeOptions" label="Type"
            @change="$refs['typeselect'].blur()" :loading="loading">
          </v-select>
          </v-row>
          <v-row>
            <v-select ref="locationselect" v-model="location" :items="locations" item-text="name" label="Location"
              @change="$refs['locationselect'].blur()" :loading="loading">
            </v-select>
            <v-btn class="ma-1" small color="primary" @click="scanLocationQrCode">
              <span>Scan location <v-icon dark>mdi-qrcode-scan</v-icon></span>
            </v-btn>            
            <ScanOverlay v-if="scanQrCode" @codeRead="onQrDecoded" @hide="closeLocationQrCodeScan">            
            </ScanOverlay>
          </v-row>
          
          <v-row>
            <span>Scanned codes: {{ scannedCodes.length }}</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-virtual-scroll :items="scannedCodes" itemHeight="64" height="300">
            <template v-slot:default="{ item }">
              <v-list-item :key="item">
                <v-list-item-content>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>
    </v-card-text>    
    <v-fab-transition>
      <v-btn color="green" fab large dark bottom right fixed @click="save">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn color="red" fab large dark bottom left fixed @click="$router.push('/history')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar top v-model="snackbar" timeout="5000" text text-center outlined>
      <h3><b class="grey--text text--darken-4">{{ snackbarMessage }}</b></h3>
      <template v-slot:action="{ attrs }">
        <v-btn @click="snackbar=false" text v-bind="attrs">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>  
</template>

<script>

import ScanOverlay from '../components/ScanOverlay.vue'

export default {
  components: {
    ScanOverlay
  },
  data() {
    return {      
      scannedCodes: [],
      scannedCodesDict: {},
      buffer: "",
      type: "",
      types: [],
      location:"",
      locations:[],
      history: [],
      loading: true,
      snackbar: false,
      snackbarMessage: "",
      scanQrCode: false
    };
  },

  mounted() {
    window.addEventListener("keypress", this.handleKeyPress);
    this.$api.type.get().then(response => {
      this.types = response.data;
    });
    this.$api.location.get().then(response => {
      this.locations = response.data.items;
      this.loading = false
    })
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyPress);
  },
  computed: {
    typeOptions() {
      return this.types.map((t) => {
        return t.name;
      });
    }
  },
  methods: {
    throwSnackbar(message) {
      this.snackbarMessage = message
      this.color = 'red';
      this.snackbar = true;
    },
    readCode(code) {
      if(!(code in this.scannedCodesDict)){
        this.scannedCodesDict[code] = 0
      }

      this.scannedCodesDict[code] += 1
      this.scannedCodes = Object.keys(this.scannedCodesDict).filter(k => this.scannedCodesDict[k] >= 2)
    },
    removeDuplicates(arr) {
      return [...new Set(arr)];
    },
    handleKeyPress(e) {
      if (e.key == "Enter") {
        this.readCode(this.buffer);
        this.buffer = "";
      } else {
        this.buffer += e.key?.trim();
      }
    },
    scanLocationQrCode(){
      this.scanQrCode = true
      window.removeEventListener("keypress", this.handleKeyPress);
    },
    closeLocationQrCodeScan(){
      this.scanQrCode = false
      window.addEventListener("keypress", this.handleKeyPress);
    },
    save() {
      let time = Date.now()
      this.$api.history.insert({
        codes: this.scannedCodes,
        type: this.type,
        location: this.location,
        time: this.$moment(time).toISOString()
      }).then(() => {
        console.log('location:', this.location);
        this.$router.push('/history');
      })
    },
    onQrDecoded(value){
      this.scanQrCode = false
      var scannedLocation = this.locations.find(location => location._id === value);
      if (scannedLocation) {
        this.location = scannedLocation
      } else {
        this.throwSnackbar('The scanned QR Code does not match a location')
      }
      window.addEventListener("keypress", this.handleKeyPress);
    },
  },
};
</script>
