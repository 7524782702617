<template>
  <v-data-table :headers="headers" :items="assets" class="elevation-1" :loading="loading"
    :server-items-length="assetCount" :options.sync="options">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Assets</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-file-input v-model="file" outlined dense hide-details :loading="uploading" @change="uploadFile"
          label="Upload an asset sheet"></v-file-input>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Serial",
          value: "serial",
          sortable: true,
        },
        {
          text: "Model",
          value: "model",
          sortable: true
        }
      ],
      assets: [],
      assetCount: 0,
      options: {},
      loading: true,
      uploading: false,
      file: null
    };
  },
  mounted() {
    this.getAssets();
  },
  watch: {
    options() {
      this.getAssets()
    },
  },
  methods: {
    getAssets() {
      this.loading = true
      this.$api.asset.get(this.options).then(response => {
        this.assets = response.data.items;
        this.assetCount = response.data.total
        this.loading = false;
      })
    },
    uploadFile() {
      if (this.file) {
        this.uploading = true
        this.$api.asset.upload(this.file).then(() => {
          this.getAssets()
          this.uploading = false
          this.file = null
        })
      }
    }
  },
};
</script>