<template>
  <v-card>
    <v-data-table :headers="headers" :items="items" show-expand item-key="time"
      @click:row="handleRowClick" :loading="loading">
      <template v-slot:top >
        <v-toolbar flat class="breakWord">
          <v-layout row>
            <v-toolbar-title>Scan History</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-layout>
          <v-layout row justify-end class="ma-1">
          <v-btn small class="ma-1" color="primary" @click="$router.push('/scan')"> New
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn small class="ma-1" color="warning" @click.stop="clearHistoryDialog()">              
          Clear All
              <v-icon dark>mdi-bucket-outline</v-icon>
            </v-btn>
          </v-layout>

                <!-- delete all region -->  
            <v-dialog v-model="dialogDeleteAll" max-width="500px">
            <v-card>
                <v-card-title class="breakWord">Are you sure you want to delete All History?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn color="blue darken-1" text @click="closeClearHistory()">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="clearHistory()">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <!-- end delete region -->            

        </v-toolbar>
      </template>
      <template v-slot:[`item.time`]="{ value }">{{ value | moment("MM/DD/YYYY hh:mm:ss a") }}</template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-chip-group column>
            <v-chip v-for="code in item.codes" :key="code" label>
              {{ code }}
            </v-chip>
          </v-chip-group>
        </td>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click.stop="exportHistory(item)">              
          <v-icon color="primary">mdi-download-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn icon @click.stop="deleteScan(item)">              
          <v-icon color="warning">mdi-bucket-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-fab-transition>

    </v-fab-transition>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn icon @click.stop="deleteScan(item)">              
          <v-icon color="primary">mdi-bucket-outline</v-icon>
        
        </v-btn>
      </template>
    <!-- delete region -->  
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="breakWord">Are you sure you want to delete this scan?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              
              <v-btn color="blue darken-1" text @click="closeDeleteScan()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteScanConfirm()">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- end delete region -->
    
    <v-snackbar 
      top 
      :color="color" 
      v-model="value" 
      timeout="2000"
      text
      text-center
      outlined
    >
      <h3><b class="grey--text text--darken-4">{{snackbarMessage}}</b></h3>
    </v-snackbar>            
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Time", value: "time", sortable: true },
        { text: "Type", value: "type", sortable: true },
        { text: "Location", value: "location", sortable: true },
        { text: "Scanned Codes", value: "numberScanned", sortable: true, align: "center" },
        { text: "Export", value: 'action', sortable: false  },
        { text: "Delete", value: 'delete', sortable: false  },
        { value: 'data-table-expand' }
      ],
      history: [],
      loading: true,
      defaultScan: {
        name: "",
      },
      editedScan: {
        name: "",
      },
      dialogDelete: false,
      dialogDeleteAll: false,
      snackbarMessage: '',
      value: false,
      color: '',
    };
  },
  mounted() {
    this.$api.history.get().then(response => {
      this.history = response.data;
      this.loading = false;
    });
  },
  computed: {
    items() {
      return this.history.map(c => {
        return {
          time: c.time,
          codes: c.codes,
          type: c.type,
          location: c.location,
          numberScanned: c.codes.length,
          id: c._id
        }
      })
    }
  },
  methods: {
    clearHistoryDialog() {
      console.log('clear all triggered');
      this.dialogDeleteAll = true;
    },

    clearHistory() {
      console.log("Confirmed delete");
      this.history = [];
      this.$api.history.clear()
      this.closeClearHistory();
      this.getHistory();
    },

    closeClearHistory() {
      console.log("Close Clear History");
      this.dialogDeleteAll = false;
      this.$nextTick(() => {
        this.editedScan = Object.assign({}, this.defaultScan);
      });
    },

    exportHistory(item) {
      console.log(item);
      if (item.id === '' || item.id === null || item.codes.length===0) {
        this.snackbarMessage = 'Invalid Export';
        this.value = true;
        this.color = "red lighten-5";
        // let snackbar = { color: "red lighten-5", show: true, message: 'Invalid Export' };
        // console.log('snackbar: hist', snackbar)
        // this.$api.methods.showSnackbar(snackbar);
      } else {
        this.$api.history.export(item.id);
      }
      
    },
    deleteScan(item) {
      console.log("Delete scan id -> ", item.id);
      this.editedScan = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteScanConfirm() {
      console.log("Confirm delete scan id -> ", this.editedScan.id);
      this.dialogDelete = false;
      this.$api.history.delete(this.editedScan.id).then(() => {
        this.closeDeleteScan();
        
        this.snackbarMessage = 'Scan deleted.';
        this.value = true;
        this.color = "red lighten-5";
        this.getHistory();
      })
    },

    closeDeleteScan() {
      console.log("Close delete scan id -> ", this.editedScan.id);
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedScan = Object.assign({}, this.defaultScan);
      });
    },

    handleRowClick(value) {
      console.log(value);
      arguments[1].expand(!arguments[1].isExpanded);
    },

    getHistory() {
    this.$api.history.get().then(response => {
      console.log("history cleared");
      this.history = response.data;
      this.loading = false;
    });
    }
  },
};
</script>
<style scoped>
.breakWord {
  word-break: break-word;
}
</style>