import axios from "axios"

let backendUrl = "";

function getToken() {
    return localStorage.getItem('token')
}

function getHeaders() {
    return {
        'XApiKey': getToken()
    }
}

export default {
    
    history: {
        get() {
            return axios.get(`${backendUrl}/api/history`, { headers: getHeaders() })
        },
        clear() {
            return axios.delete(`${backendUrl}/api/history`, { headers: getHeaders() })
        },
        insert(scan) {
            return axios.post(`${backendUrl}/api/history`, scan, { headers: getHeaders() })
        },
        delete(id) {
            return axios.delete(`${backendUrl}/api/history/${id}`,{ headers: getHeaders() })
        },
        async export(id) {            
            axios.get(`${backendUrl}/api/history/export/${id}`, { headers: getHeaders(), responseType: 'arraybuffer' })
                .then(res => {
                    let blob = new Blob([res.data], { type: 'text/csv' });
                    let url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch(err => {
                    console.log("error", err);
                });
        },
        async put(scan) {
            try{
                let res = await axios.put(`${backendUrl}/api/history`, scan, { headers: getHeaders() })
                console.log("res.data", res.data);
                this.message = res.data;
                return res.data;
            } catch(err) {
                this.message = err;
                console.log("err", err);
                return err;
            }
        },
        getSummaries() {
            return axios.get(`${backendUrl}/api/history/summary`, { headers: getHeaders() })
        },
        getViews(id1, id2) {
            return axios.get(`${backendUrl}/api/history/view/${id1}/${id2}`, { headers: getHeaders() })
        }
    },
    type: {
        get() {
            return axios.get(`${backendUrl}/api/type`, { headers: getHeaders() })
        },
        getOne(id) {
            return axios.get(`${backendUrl}/api/type/${id}`, { headers: getHeaders() })
        },
        delete(id) {
            return axios.delete(`${backendUrl}/api/type/${id}`, { headers: getHeaders() })
        },
        async insert(type) {
            try {
                console.log("insert type input ", type);
                let res = await axios.post(`${backendUrl}/api/type`, type, { headers: getHeaders() });
                console.log("Success result", res);
                this.typeError = '';
                return res;
            } catch (err) {
                this.typeError = err.response.data;
                console.log("err", err.response);
                return err.response.data;
            }
        }
    },
    location: {
        get() {
            return axios.get(`${backendUrl}/api/location/GetLocations`, { headers: getHeaders() })
        },
        getOne(id) {
            return axios.get(`${backendUrl}/api/location/GetLocation/${id}`, { headers: getHeaders() })
        },
        delete(id) {
            return axios.delete(`${backendUrl}/api/location/DeleteLocation/${id}`, { headers: getHeaders() })
        },
        async insert(location) {
            try {
                console.log("insert location input ", location);
                let res = await axios.post(`${backendUrl}/api/location/InsertLocation`, location, { headers: getHeaders() });
                console.log("Success result", res);
                this.locationError = '';
                return res;
            } catch (err) {
                this.locationError = err.response.data;
                console.log("err", err.response);
                return err.response.data;
            }
        },        
        async export(id) {            
            axios.get(`${backendUrl}/api/location/qrcode/${id}`, { headers: getHeaders(), responseType: 'arraybuffer' })
                .then(res => {
                    let blob = new Blob([res.data], { type: 'image/jpeg' });
                    let url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${id}.jpeg`)
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log("error", err);
                });
        }
    },
    mapping: {
        get() {
            return axios.get(`${backendUrl}/api/mapping`, { headers: getHeaders() })
        },
        getOne(id) {
            return axios.get(`${backendUrl}/api/mapping/${id}`, { headers: getHeaders() })
        },
        delete(id) {
            return axios.delete(`${backendUrl}/api/mapping/${id}`, { headers: getHeaders() })
        },
        insert(mapping) {
            return axios.post(`${backendUrl}/api/mapping`, mapping, { headers: getHeaders() })
        },
        getLabelBySerial(serial) {
            return axios.get(`${backendUrl}/api/mapping/label/${serial}`, { headers: getHeaders() })
        },
        getSerialByLabel(label) {
            return axios.get(`${backendUrl}/api/mapping/serial/${label}`, { headers: getHeaders() })
        },
    },
    asset: {
        get(options) {
            return axios.get(`${backendUrl}/api/asset`, { params: options, headers: getHeaders() })
        },
        getOne(serial) {
            return axios.get(`${backendUrl}/api/asset/${serial}`, { headers: getHeaders() })
        },
        getSerials() {
            return axios.get(`${backendUrl}/api/asset/serial`, { headers: getHeaders() }) 
        },
        upload(file) {
            let formData = new FormData();
            formData.append("file", file)
            let headers = getHeaders()
            headers['Content-Type'] = 'multipart/form-data'
            return axios.post(`${backendUrl}/api/asset/upload`, formData, {
                headers: headers
            })
        }
    },
    search: {
        get(search) {
            return axios.get(`${backendUrl}/api/search/${search}`, { headers: getHeaders() })
      }  
    },
    data() {
        return {
            scan: {},
            scanArr:[],
            lastPage: '',
            isReturning: false,
            labelSelected: [],
            label: {
                id: '',
                model: '',
                name: '',
                serial: '',
                ratio: 0,
                labelFound: false,
            },
            selected:[],
            message: '',
            color: '',
            isSuccess: '',
            display: false,
            show: false,
            typeError: '',
            locationError:'',
            item: {},
            snackbarColor:'',
            snackbarShow:false,
            snackbarMessage: '',
            snackbarTime: '2000',
            searchString: null,
            
        }
    },
    
    methods: {
        download(data) {
            let blob = new Blob([data], {type: 'text/csv'})
            let url = window.URL.createObjectURL(blob);
            window.open(url);
        },
        // showSnackbar(snackbar) {
        //     console.log('snackbar api: ', snackbar);
        //     let timeout = 0;
        //     if (snackbar.show) {
        //         this.snackbar.show = false;
        //         timeout = 300;
        //     }
        //     setTimeout(() => {
        //         this.snackbar.show = true;
                
        //         this.snackbar.message = snackbar.message;
        //     }, timeout);      
        // },
        // hideSnackbar() {
        //     this.snackbar.show = false;
        //     }

    },
    
    
}