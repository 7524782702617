import { render, staticRenderFns } from "./SearchDisplayer.vue?vue&type=template&id=60002cc1&scoped=true&"
import script from "./SearchDisplayer.vue?vue&type=script&lang=js&"
export * from "./SearchDisplayer.vue?vue&type=script&lang=js&"
import style0 from "./SearchDisplayer.vue?vue&type=style&index=0&id=60002cc1&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60002cc1",
  null
  
)

export default component.exports